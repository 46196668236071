import {TranslationService} from "./service/translation.service";

declare let window: any;

function initialize() {
    TranslationService.getInstance().init();
}

if (document) {
    document.addEventListener('DOMContentLoaded', function(event) {
        initialize();
    });
}

export class BTRB {
    public static getText(pKey): string {
        return TranslationService.getInstance().translate(pKey);
    }

    public static onReady(pCallback: Function) {
        TranslationService.getInstance().onReady(pCallback);
    }

    public static getLanguage(){
        return TranslationService.getInstance().getLanguage();
    }
}

window.BTRB = BTRB;