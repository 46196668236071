import {Request} from "../../fetch/model/request";
import {FetchService} from "../../fetch/fetch.service";
import {Utils} from "../../utils/utils";

export class TranslationService {

    private static instance: TranslationService;
    private static translationMap: any = {};
    private callbacks: Function[] = [];
    private static lang: string = null;
    private static dtLastUpdate: string = null;
    private notFoundKeys: string[] = [];
    private isTimeOutActive: boolean = false;

    private constructor(){}

    /**
     * Crea la instancia del Singleton.
     * @return {TranslationService}
     */
    static getInstance(): TranslationService {
        if (!TranslationService.instance) {
            TranslationService.instance = new TranslationService();
        }
        return TranslationService.instance;
    }

    public init(): void {
        if (!this.isTranslationsLoaded()) {
            this.loadTranslations().then(function (result) {
                TranslationService.translationMap = result.translationMap;
                TranslationService.lang = result.lang;
                TranslationService.dtLastUpdate = result.dtLastUpdate;
                this.processCallbacks();
            }.bind(this))
                .catch(function(err) {
                    console.warn("Ocurrió un error en el servidor intentando cargar las traducciones");
                    this.processCallbacks();
                }.bind(this));
        } else {
            TranslationService.translationMap = JSON.parse(localStorage.getItem("translations"));
            TranslationService.lang = JSON.parse(localStorage.getItem("lang"));
            TranslationService.dtLastUpdate = JSON.parse(localStorage.getItem("translationsDtLastUpdate"));
        }
    }

    /**
     * Call endpoint to load the translation map.
     */
    private loadTranslations(): Promise<any> {
        return new Promise((resolve, reject) => {
            const request = new Request();
            request.setMethod('GET');
            request.setUrl('v1.0/translations');
            request.setSuccess((response) => {
                let translationJson = JSON.parse(response);
                if (translationJson.translationMap && translationJson.lang) {
                    localStorage.setItem("lang", JSON.stringify(translationJson.lang));
                    localStorage.setItem("translations", JSON.stringify(translationJson.translationMap));
                    localStorage.setItem("translationsDtLastUpdate", JSON.stringify(translationJson.dtLastUpdate));
                    resolve(translationJson);
                }
            });
            request.setFail(() => reject());
            FetchService.executeEndpoint(request);
        });
    }

    private isTranslationsLoaded(): boolean {
        if ((localStorage.getItem("translations") !== null) && (localStorage.getItem("lang") !== null) && (localStorage.getItem("translationsDtLastUpdate") !== null)) {
            let dtLastUpdate = (<HTMLInputElement>Utils.findElementById("translationDtLastUpdateHIDDEN"))?.value;
            let lang = (<HTMLInputElement>Utils.findElementById("translationLangHIDDEN"))?.value;
            return (dtLastUpdate === JSON.parse(localStorage.getItem("translationsDtLastUpdate")) && lang === JSON.parse(localStorage.getItem("lang")));
        } else {
            return false;
        }
    }

    public translate(pKey: any): string {
        if (pKey !== "") {
            let item = TranslationService.translationMap[this.replaceKey(pKey)];
            if (item !== undefined) {
                if(item.length > 0){
                    if(this.startsWithCapital(pKey)){
                        return this.capitalizeFirstLetter(item);
                    } else {
                        return this.lowerFirstLetter(item);
                    }
                }
                return item;
            } else {
                //Ya no hace falta enviar al servidor las claves no encontradas
                //this.logNotFound(pKey);
                return pKey;
            }
        } else {
            return "";
        }
    }

    private startsWithCapital(word): boolean {
        return word.charAt(0) === word.charAt(0).toUpperCase()
    }

    private capitalizeFirstLetter(word): string {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }

    private lowerFirstLetter(word): string {
        return word.charAt(0).toLowerCase() + word.slice(1);
    }

    private replaceKey(pKey): string {
        let xKey = pKey.toLowerCase()
            .replace(/[.()\-]/gi, '')
            .replace(/\s/g, '_')
            .replace(/\\/g, '_')
            .replace(/:\s*/g, '#');
        return this.replaceAcute(xKey);
    }

    private replaceAcute(pLine: string): string {
        let xLine = pLine;
        xLine = xLine.replace(/&aacute;/g, decodeURI('%c3%a1'));//'á'
        xLine = xLine.replace(/&Aacute;/g, decodeURI('%c3%81'));//'Á'
        xLine = xLine.replace(/&eacute;/g, decodeURI('%c3%a9'));//'é'
        xLine = xLine.replace(/&Eacute;/g, decodeURI('%c3%89'));//'É'
        xLine = xLine.replace(/&iacute;/g, decodeURI('%c3%ad'));//'í'
        xLine = xLine.replace(/&Iacute;/g, decodeURI('%c3%8d'));//'Í'
        xLine = xLine.replace(/&oacute;/g, decodeURI('%c3%b3'));//'ó'
        xLine = xLine.replace(/&Oacute;/g, decodeURI('%c3%93'));//'Ó'
        xLine = xLine.replace(/&uacute;/g, decodeURI('%c3%ba'));//'ú'
        xLine = xLine.replace(/&Uacute;/g, decodeURI('%c3%9a'));//'Ú'
        xLine = xLine.replace(/&ntilde;/g, decodeURI('%c3%b1'));//'ñ'
        xLine = xLine.replace(/&Ntilde;/g, decodeURI('%c3%91'));//'Ñ'
        xLine = xLine.replace(/&nbsp;/g, decodeURI('%5f'));//'_'
        xLine = xLine.replace(/&iquest;/g, decodeURI('%C2%BF'));//'¿'

        //quitar los acentos
        xLine = xLine.normalize('NFD')
            .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi,"$1$2")
            .normalize();
        return xLine;
    }

    public onReady(pCallback: Function) {
        if (this.isTranslationsLoaded()){
            pCallback();
        }
        else {
            this.callbacks.push(pCallback);
        }
    }

    private processCallbacks(): void {
        for(let f of this.callbacks){
            f();
        }
        this.callbacks = [];
    }

    public getLanguage(): string {
        return TranslationService.lang;
    }

    /*
    Este metodo se depreca, ya no se tienen que mandar las claves no encontradas
     */
    private logNotFound(pKey: string): void {
        this.notFoundKeys.push(pKey);
        if (!this.isTimeOutActive) {
            this.isTimeOutActive = true;
            setTimeout(function () {
                let data = {keys: this.notFoundKeys};
                const request = new Request();
                request.setMethod('PUT');
                request.setUrl('v1.0/translations/log-not-found');
                request.setHeaders({'Content-Type': 'application/json'});
                request.setBody(JSON.stringify(data));
                request.setFail(() => {
                    console.error('Hubo un problema con la petición');
                });
                FetchService.executeEndpoint(request);
                this.notFoundKeys = [];
                this.isTimeOutActive = false;
            }.bind(this), 3000);
        }
    }

}