export class Request {

    private isRefresh: boolean = false;
    private method: string = 'GET';
    private url: string;
    private headers: any = {};
    private body: string;
    private success:(response) => void;
    private fail:(response) => void;

    constructor() {};

    public setIsRefresh(isRefresh: boolean): void {
        this.isRefresh = isRefresh;
    }

    public getIsRefresh(): boolean {
        return this.isRefresh;
    }

    public setMethod(method: string): void {
        this.method = method;
    }

    public getMethod(): string {
        return this.method;
    }

    public setUrl(url: string): void {
        this.url = url;
    }

    public getUrl(): string {
        return this.url;
    }

    public setHeaders(headers: any): void {
        this.headers = headers;
    }

    public getHeaders(): any {
        return this.headers;
    }

    public setBody(body: string): void {
        this.body = body;
    }

    public getBody(): string {
        return this.body;
    }

    public setSuccess(success: (response) => void): void {
        this.success = success;
    }

    public getSuccess(): (response) => void {
        return this.success;
    }

    public setFail(fail: (response) => void): void {
        this.fail = fail;
    }

    public getFail(): (response) => void {
        return this.fail;
    }
}